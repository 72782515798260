import PinField, { usePinField } from "react-pin-field"
import styled from "styled-components"

interface Props {
  onSubmit: (otp: string) => void
}

export const InputVerify = ({ onSubmit }: Props) => {
  const pinFieldController = usePinField()
  const handleSubmitOtp = (otp: string) => {
    onSubmit(otp)
    pinFieldController.setValue("")
  }
  return (
    <PinRow>
      <PinField
        length={6}
        handler={pinFieldController}
        autoFocus={true}
        onComplete={handleSubmitOtp}
        type="number"
        inputMode="numeric"
      />
    </PinRow>
  )
}

const PinRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    width: 36px;
    height: 36px;
    text-align: center;
    border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
    border-radius: 4px;
    height: 48px;
    width: 48px;
    -webkit-appearance: none;
  }
`
