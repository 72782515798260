import { useContext, useState } from "react"
import styled from "styled-components"
import { useRouter } from "next/router"
import { VerifyPhoneModal } from "@components/VerifyPhoneModal"
import {
  BREAKPOINT_QUERY,
  Button,
  InputText,
  Spacer,
  Text,
  useModal,
} from "@openui"
import { hydrateServerSideProps } from "@state/hydrate"
import {
  IdentityContext,
  useRequestVerificationCode,
} from "providers/IdentityProvider"

export default function LoginPage() {
  const identity = useContext(IdentityContext)
  const router = useRouter()
  const verificationCodeMutation = useRequestVerificationCode()
  const verifyModalController = useModal()
  const [phoneNumber, setPhoneNumber] = useState("")
  const handleSubmit = () => {
    if (phoneNumber.length < 10) return
    verificationCodeMutation.mutate({
      phone: phoneNumber,
    })
    verifyModalController.open()
  }
  const handleVerifySuccess = (data: { token: string }) => {
    identity.login(data.token)
    router.push("/")
  }
  return (
    <Page>
      <Container>
        <Text style="DisplayHeading/Large">Enter your phone number</Text>
        <Spacer size={4} />
        <Text style="Body/Regular">
          We&#39;ll send you a text to verify your number.
        </Text>
        <Spacer size={5} />
        <InputText
          placeholder="Phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
          mask="+1 (000) 000-0000"
        />
        <Spacer size={5} />
        <Button
          width="100%"
          isDisabled={phoneNumber.length < 10}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        <Spacer size={60} />
      </Container>
      <VerifyPhoneModal
        controller={verifyModalController}
        phoneNumber={phoneNumber}
        onResendCode={handleSubmit}
        onSuccess={handleVerifySuccess}
      />
    </Page>
  )
}

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  ${BREAKPOINT_QUERY["mobile"](`
    padding: 16px;
`)}
`

export const getServerSideProps = hydrateServerSideProps()
