import styled from "styled-components"
import { useContext } from "react"
import { useMutation } from "@tanstack/react-query"
import {
  Icon,
  IconKey,
  Modal,
  ModalController,
  Spacer,
  Text,
  formatPhoneNumber,
} from "@openui"
import { Loading } from "@openui"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import apiClient from "@api/client"
import { InputVerify } from "openui/components/InputVerify"

interface Props {
  controller: ModalController
  phoneNumber: string
  onResendCode: () => void
  onSuccess: (data: { token: string }) => void
}

export const VerifyPhoneModal = ({
  controller,
  phoneNumber,
  onResendCode,
  onSuccess,
}: Props) => {
  const merchantConfig = useContext(MerchantConfigContext)
  const confirmVerificationCodeMutation = useMutation<
    { token: string },
    Error,
    { phone: string; code: string }
  >({
    mutationFn: async ({ phone, code }) => {
      const res = await apiClient.post(`verify/`, {
        phone,
        code,
        merchant_id: merchantConfig.config?.merchantId,
      })
      if (res?.statusCode === 200 && res?.body)
        return res.body as { token: string }
      throw Error((res?.body as { error: string }).error)
    },
    onSuccess: (data) => {
      onSuccess(data)
      controller.close()
    },
  })
  const handleSubmitOtp = (otp: string) => {
    confirmVerificationCodeMutation.mutate({
      phone: phoneNumber,
      code: otp,
    })
  }
  return (
    <Modal
      controller={controller}
      heading="Verify Phone Number"
      right={<Icon iconKey={IconKey.Close} onClick={controller.close} />}
    >
      <Text style="Body/Regular" color="Content/Secondary">
        Enter the code we sent to {formatPhoneNumber(phoneNumber)}.
      </Text>
      <Spacer size={3} />
      {!confirmVerificationCodeMutation.isPending &&
        !confirmVerificationCodeMutation.isSuccess && (
          <InputVerify onSubmit={handleSubmitOtp} />
        )}
      {(confirmVerificationCodeMutation.isPending ||
        confirmVerificationCodeMutation.isSuccess) && (
        <Center>
          <Loading />
        </Center>
      )}
      {confirmVerificationCodeMutation.isError && (
        <>
          <Spacer size={3} />
          <Text style="Body/Regular" color="Content/Error">
            {confirmVerificationCodeMutation.error.message}
          </Text>
        </>
      )}
      <Spacer size={3} />
      <Text style="Body/Regular" color="Content/Secondary">
        Didn&#39;t get a code?{" "}
        <Resend onClick={onResendCode}>Resend now.</Resend>
      </Text>
      <Spacer size={5} />
    </Modal>
  )
}

const Resend = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
